import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CFormSelect,
	CInputGroup,
	CInputGroupText,
	CFormCheck,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CImage,
	CPagination,
	CPaginationItem,
	CLink,
	CTooltip,
	CDropdown,
	CDropdownToggle,
	CDropdownMenu,
	CDropdownItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { isMobile } from "react-device-detect";
import {
	MyPagination,
	MyDialogform,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc } from '../helpers/functions'

const pjson = require('../../package.json')
const User 	= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif 
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 15) : (uSettingObj.jml_tabel || 30);
	const uKeywordObj				= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uIsHapusallow				= uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN";
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	
	const uHeaderActionObj  = useSelector(state => state.gInitHeaderAction);
	const uHandelView 		= uHeaderActionObj.isHeaderView || false;
	const uHandelTambah 	= uHeaderActionObj.isHeaderTambah || false;

	//--VIEWS_VARS--/
	const uRoledefault		= [{value:"ENTRI",caption:"ADMINISTRASI"}]
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uIDselect,setIDselect]		= React.useState(0);
	const [uFirstLoad,setFirstLoad]		= React.useState("YA");
	const [uDataroleArr,setDataroleArr]	= React.useState(uRoledefault);

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	//--END VIEWS_VARS--/

	//---FORM_VARS--/
	const [isShowForm,setShowForm]			= React.useState(false);
	const [uInpusername,setInpusername]		= React.useState("");
	const [uInpusercaption,setInpusercaption]= React.useState("");
	const [uInppasswd1,setInppasswd1]		= React.useState("");
	const [uInppasswd2,setInppasswd2]		= React.useState("");
	const [uInphak,setInphak]				= React.useState("ENTRI");
	const [uHeaderdlg,setHeaderdlg]			= React.useState("");
	const [uInpCabangselectAll,setInpCabangselectAll]= React.useState(false);
	const [uInpCabangIDArr,setInpCabangIDArr]= React.useState([]);
	//---END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData	= document.getElementById("idjmldata");
	//--END DOM_VARS--/

	//--HANDEL--/
	const hdlToggledlg = () =>{ setShowForm(false) }
	const hdlKlikOffline = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].usercaption||"").toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Set Status OFFLINE: <B>"+vCaptionUpper+"</B>"+
			"<BR><BR>Apakah Anda Yakin ?"+
			""))) apiProsesonline(_IDTABEL);
	}
	const hdlKlikEdit = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].usercaption||"").toUpperCase();
		setRowselect(vIdxRow);

		setInpusercaption(uDatamainArr[vIdxObj].usercaption||"");
		setInpusername(uDatamainArr[vIdxObj].username||"");
		setInphak(uDatamainArr[vIdxObj].hak||"ENTRI");

		setHeaderdlg("Edit Data: "+vCaptionUpper);
		setShowForm(true);
	}
	const hdlKlikHapus = async(_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		setIDselect(_IDTABEL);
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		const vIdxRow	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL)
		const vCaptionUpper = (uDatamainArr[vIdxObj].usercaption||"").toUpperCase();
		setRowselect(vIdxRow);

		if(await(Konfirm("Hapus Data: <B>"+vCaptionUpper+"</B>"+
			"<BR><BR>Apakah Anda Yakin ?"+
			""))) apiProseshapus(_IDTABEL);
	}
	const hdlKlikRole = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;if(_IDTABEL <= 0) return;

		const vTmpObj	= { initID:_IDTABEL, initPage:uPageActive }
		uDispatch({type: "set", gInitTabelSelected: vTmpObj})
		uNavigate("/subdasar/user/setrole/"+_IDTABEL);
	}
	const hdlKlikCabangaksesall	= () => {
		let vIscheckedall = true;
		uDatacabangArr.forEach(vItems => {
			if(!uInpCabangIDArr.includes(vItems.id)) {
				vIscheckedall = false;
			} 
		});

		uInpCabangIDArr.splice(0,uInpCabangIDArr.length); 
		if(vIscheckedall) return;

		let vTmpAll	= []
		uDatacabangArr.forEach(vItems => vTmpAll = [...vTmpAll,vItems.id]);
			
		setInpCabangIDArr(vTmpAll);
		setEfekview(!uEfekview);
	}
	const hdlChangeCabangakses	= (_EVN) => {
		const vValueID	= _EVN.target.value;
		const vIschecked= _EVN.target.checked;

		//showToast("vValueID = "+vValueID+" | vIschecked = "+vIschecked)
		if(vIschecked) {

			setInpCabangIDArr([...uInpCabangIDArr,vValueID])
		} else {
			const vIdx = uInpCabangIDArr.findIndex(vItems => vItems===vValueID);
			uInpCabangIDArr.splice(vIdx,1);
		}
		setEfekview(!uEfekview);
	}
	const hdlKlikDlgsimpan = () => {
		let vElFocus;
		vElFocus = document.getElementById("inpusercaption");
		if(uInpusercaption.trim()==="") {
			vElFocus.focus();
			showToast("Nama User Harus Diisi !");return;
		}
		vElFocus = document.getElementById("inpusername");
		if(uInpusername.trim()==="") {
			vElFocus.focus();
			showToast("Username Harus Diisi !");return;
		}
		if(parseInt(uIDselect) <= 0) {
			vElFocus = document.getElementById("inppasswd1");
			if(uInppasswd1 === "") {
				vElFocus.focus();
				showToast("Password Harus Diisi !"); return;
			}
			vElFocus = document.getElementById("inppasswd2");
			if(uInppasswd2 === "") {
				vElFocus.focus();
				showToast("Konfirmasi Password Harus Diisi !"); return;
			}
		}
		vElFocus = document.getElementById("inppasswd1");
		if(uInppasswd1 !=="" || uInppasswd2!=="") 
			if(uInppasswd1 !== uInppasswd2) {
				vElFocus.focus();
				showToast("Password dan Konfirmasi Password Harus Sama !"); return;
			}
		vElFocus = document.getElementById("inphak");
		if(uInphak!=="FREEROLE" 
			&& uInphak!=="ADMIN" && uInphak!=="ENTRI" 
			&& uInphak!=="DOKTER"
		) {
			vElFocus && vElFocus.focus();
			showToast("Hak Akses Harus Dipilih !");return;
		}
		if(parseInt(uIDselect||0)<=0)
			if(uInpCabangIDArr.length <= 0) {
				showToast("Akses Cabang Harus Dipilih !");return;
			}//-*/

		apiProsessimpan(uIDselect);
	}

	//--END HANDEL--/

	//--INIT--/
	const initFormAwal = () => {
		//console.log("(User - initFormAwal) uDatarole => "+JSON.stringify(uDatarole));
		setIDselect(0)

		setInpusername("")
		setInpusercaption("")
		setInppasswd1("")
		setInppasswd2("")
		setInphak((uDataroleArr.length && uDataroleArr[0].value||"ENTRI")||"ENTRI");

		setInpCabangIDArr([]);
		setInpCabangselectAll(false);
	}
	//--END INIT--/

	//--CONTENT--/
	const contentMain = () => {
		if(uHtml500msg) return (<></>);//->

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);
		const vKeyword 		= UFunc.filterRegex(uKeywordObj.kwd_user || "");
		const vKeywordcolor = uSettingObj.color_keyword || "#D725FF";
		const vReplace 		= new RegExp(vKeyword,"ig");

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));

		return (
		<table className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-md-table-row align-top text-center classfontsmaller">
			<th width={35} className="px-1 pe-1">No</th>
			<th width={35} className="px-1">&middot;</th>
			<th className="px-1">
			<CRow className="mx-0">
			<CCol md="3" className="text-start">{uBahasaObj.word_username||"Username"}</CCol>
			<CCol md="5" className="text-start">{uBahasaObj.caption_usercaption||"Nama Pengguna"}</CCol>
			<CCol md="2" className="">{uBahasaObj.caption_hakakses||"Hak Akses"}</CCol>
			<CCol md="2" className="">&middot;</CCol>
			</CRow>
			</th>
			</tr>

			<tr className="d-md-none">
				<th width={30} className="p-0"/>
				<th width={30} className="p-0"/>
				<th className="p-0"/>
			</tr>
		</thead>
		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				id,tgl_input,userinput,onlinestatus,username,usercaption,hak,
				cabang_arr,modullist
			} = vItems;

			vNumber++;
			const vCaptionUpper	= (usercaption||"").toUpperCase();

			const vLangshak	= uBahasaObj["hakuser_"+(hak||"").toLowerCase()] || (hak||"UNDF");

			const vUsername	= vKeyword==="" ? (username||"") 
				: (username||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUsercaption	= vKeyword==="" ? (usercaption||"") 
				: (usercaption||"").replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserhak	= vKeyword==="" ? (vLangshak) 
				: (vLangshak).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			const vUserinput	= vKeyword==="" ? UFunc.Usercaptionfilter((userinput||"UNDF"),1) 
				: (UFunc.Usercaptionfilter((userinput||"UNDF"),1)).replace(vReplace,"<strong><U style='color:"+vKeywordcolor+"'>"+vKeyword.toUpperCase()+"</U></strong>");
			
			const vCabangArr= cabang_arr || [];
			
			return (
			<tr id={"idtr"+id} key={vKeys} className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}>
				<td className="px-1">{vNumber}.</td>
				<td className="px-1">
					{(onlinestatus||false)===true ? (
					<CTooltip content={"--"+(uBahasaObj.status_online||"ONLINE")+": "+vCaptionUpper+" (Klik Untuk SET Offline)"}>
						<CLink onClick={()=>hdlKlikOffline(id)} className="classcursorpointer">
						<CIcon icon="cilContact" className="text-success classblinking classverticalmiddle" height={25}/>
						</CLink>
					</CTooltip>
					) : (
					<CTooltip content={"--"+(uBahasaObj.status_offline||"Status Offline")+": "+vCaptionUpper}>
						<CIcon icon="cilBan" className="text-dark classverticalmiddle" height={20}/>
					</CTooltip>
					)}
				</td>

				<td className="px-1">
				<CRow className="mx-0">
				<CCol md="3" className="d-none d-md-block px-1 text-md-start">
					<div className="fw-bolder text-primary">{UFunc.renderHTML(vUsername)}</div>

					<span className="text-danger classfontsmaller">
						<small>[
						{UFunc.WaktuAngka(tgl_input)}
						<span className="mx-1">&middot;</span>
						{UFunc.renderHTML(vUserinput)}
						]</small>
					</span>
				</CCol>

				<CCol md="5" className="px-1 text-start">
					<div className="d-flex justify-content-between">
						<div className="text-capitalize">{UFunc.renderHTML(vUsercaption)}</div>

						{((uTokenObj.userhak||"") !== (hak||"")
							|| uTokenObj.userhak==="FREEROLE"
						) && (
						<CDropdown className="d-md-none">
						<CDropdownToggle caret={false} size="sm"
							className="p-0" 
							color="transparent">
							<CIcon icon="cilOptions" height={18}/>
						</CDropdownToggle>
						<CDropdownMenu>
							{(uTokenObj.userhak==="FREEROLE" || uTokenObj.userhak==="ADMIN") && (
							<CDropdownItem onClick={()=>hdlKlikEdit(id)}>
								<CIcon className="classikonedit align-middle mx-0 me-2" height={25}/>{uBahasaObj.caption_editdata||"Edit Data"}
							</CDropdownItem>
							)}
							
							{(uTokenObj.userhak==="FREEROLE" && hak !== "FREEROLE") && (
							<CDropdownItem onClick={()=>hdlKlikRole(id)}>
								<CImage src={pjson.homepage+"api/images/menus_role.png"} height={25} className=" align-middle mx-0 me-2"/>
								{uBahasaObj.caption_roleakses||"Role Akses Menu"}
							</CDropdownItem>
							)}
								
							{(uIsHapusallow) && (
							<CDropdownItem onClick={()=>hdlKlikHapus(id)}>
								<CIcon className="classikonhapus align-middle mx-0 me-2" height={25}/>{uBahasaObj.caption_hapusdata||"Hapus Data"}
							</CDropdownItem>
							)}
						</CDropdownMenu>
						</CDropdown>
						)}
					</div>

					{uDatacabangArr.length > 1 && (
					<>
					<div className="d-none d-md-block">
						<small className="classfontsmaller">
						{(hak||"")!=="FREEROLE" ? (
						<>
							<b>Akses Cabang</b>:&nbsp;
							{vCabangArr.map((vItemcabang,vKeycabang)=>{
								return (
								<span key={vKeycabang}>{vItemcabang.cabang_caption}{vKeycabang < vCabangArr.length - 1 && (<> &middot; </>)}</span>
								)
							})}
						</>
						) : (
							<>Akses Cabang: <b>UNLIMITED</b></>
						)}
						</small>
					</div>
					</>
					)}

					<div className="d-none d-md-block classborderbottom my-2"/>

					{(modullist||"")==="" ? (
						<div className="d-none d-md-block text-primary">&middot; Default Role &middot;</div>
					) : (
						<b className="d-none d-md-block text-warning">&middot; CUSTOM ROLE &middot;</b>
					)}
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.word_username||"Username"}</CCol>
				<CCol xs="7" className="px-1 d-md-none text-md-start fw-bolder text-primary">
					{UFunc.renderHTML(vUsername)}
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_hakakses||"Hak Akses"}</CCol>
				<CCol xs="7" md="2" className="px-1 text-md-center text-uppercase">
					{UFunc.renderHTML(vUserhak)}
				</CCol>

				<CCol xs="5" className="px-1 d-md-none text-start">{uBahasaObj.caption_inputby||"Inputby"}</CCol>
				<CCol xs="7" className="px-1 text-danger d-md-none classfontsmaller">
					<small>[{UFunc.WaktuAngka(tgl_input||"")} &middot; {UFunc.renderHTML(vUserinput)}]</small>
				</CCol>

				{uDatacabangArr.length > 1 && (
				<>
				<CCol xs="12" className="px-1 my-3 d-md-none classborderbottom"/>
				<CCol xs="12" className="px-1 d-md-none text-start fw-bolder">{uBahasaObj.caption_aksescabang||"Akses Cabang"}</CCol>
				<CCol xs="12" className="px-1 text-start classfontsmaller d-md-none">
				{(hak||"")!=="FREEROLE" ? (
					<small>
					{vCabangArr.map((vItemcabang,vKeycabang)=>{
						return (
						<span key={vKeycabang}>{vItemcabang.cabang_caption}{vKeycabang < vCabangArr.length - 1 && (<> &middot; </>)}</span>
						)
					})}
					</small>
				) : (
					<b>UNLIMITED</b>
				)}
				</CCol>
				</>
				)}

				<CCol md="2" className="px-0 text-md-center d-none d-md-block">
					{(uTokenObj.userhak||"") === (hak||"") && uTokenObj.userhak!=="FREEROLE"
					? (<>&middot;</>)
					: (
					<>
						<CTooltip content={"--"+(uBahasaObj.caption_editdata||"Edit Data")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikEdit(id)} className="classikontabel classikonedit classcursorpointer"/>
						</CTooltip>

						{(uTokenObj.userhak||"")==="FREEROLE" && ((hak||"")!=="FREEROLE") && (
						<>
						<CTooltip content={"--"+(uBahasaObj.caption_setrolemenu||"Atur User Role")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikRole(id)} className="classcursorpointer">
							<CImage src={pjson.homepage+"api/images/menus_role.png"} height={25}/>
						</CLink>
						</CTooltip>
						</>
						)}

						{uIsHapusallow && (
						<>
						<CTooltip content={"--"+(uBahasaObj.caption_hapusdata||"Hapus Data")+": "+vCaptionUpper}>
						<CLink onClick={()=>hdlKlikHapus(id)} className="classikontabel classikonhapus classcursorpointer"/>
						</CTooltip>
						</>
						)}
					</>
					)}
				</CCol>
				</CRow>
				</td>
			</tr>
			)
		})}
		</tbody>
		</table>
		)//->
	}
	const contentDlgform = () => {
		if(!isShowForm) return (<></>)//->

		let vRoleFilterArr = uDataroleArr;
		if((uTokenObj.userhak||"")!=="ADMIN"&&(uTokenObj.userhak||"")!=="FREEROLE") {
			vRoleFilterArr = uDataroleArr.filter(vItems=>{
				return (vItems.value!=="ADMIN")
			});
		}

		const vTokencabangArr = uTokenObj.user_cabangarr || [];
		const vCabangAllowArr = uTokenObj.userhak!=="FREEROLE" ? uDatacabangArr.filter(vItems => 
			vTokencabangArr.includes((vItems.id||"").toString()) )
			: uDatacabangArr;

		return (
		<CForm className="mx-lg-5">
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_usercaption||"Nama User"}</CCol>
		<CCol className="">
			<CFormInput size="sm"
				maxLength={100}
				className="text-capitalize"
				value={uInpusercaption}
				autoComplete="off"
				onChange={(e)=>setInpusercaption(e.target.value)}
				id="inpusercaption"/>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.word_username||"Username"}</CCol>
		<CCol className="">
			<CFormInput size="sm"
				maxLength={50}
				value={uInpusername}
				onChange={(e)=>setInpusername(e.target.value)}
				autoComplete="off"
				id="inpusername"/>
		</CCol>
		</CRow>

		<CRow className="my-3"><CCol className="classborderbottom"/></CRow>
		
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">Password</CCol>
		<CCol className="">
			<CFormInput size="sm" type="password"
				autoComplete="off"
				value={uInppasswd1}
				onChange={(e)=>setInppasswd1(e.target.value)}
				id="inppasswd1"/>
		</CCol>
		</CRow>
		
		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">
			<span className="d-lg-none classfontsmaller"><small>Konfirmasi Password</small></span>
			<span className="d-none d-lg-block">Konfirmasi Password</span>
		</CCol>
		<CCol className="">
			<CFormInput size="sm" type="password"
				autoComplete="off"
				value={uInppasswd2}
				onChange={(e)=>setInppasswd2(e.target.value)}
				onKeyDown={(e)=>{
					!document.getElementById("inphak") && UFunc.
						hdlKeyDownInput(e,"btnDialogSimpan")
				}}
				id="inppasswd2"/>
		</CCol>
		</CRow>
		
		<div className="my-3 classborderbottom"/>

		<CRow className="my-2">
		<CCol xs="4" className="text-md-end">{uBahasaObj.caption_hakakses||"Hak Akses"}</CCol>
		<CCol className="">
			{(uInphak!=="FREEROLE") && (
			<CFormSelect size="sm"
				value={uInphak}
				onChange={(e)=>setInphak(e.target.value)}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inphak">
			{vRoleFilterArr.map((vItems,vKeys)=>{
				return (<option key={vKeys} value={vItems.value}>{vItems.caption}</option>)
			})}
			</CFormSelect>
			)}
			{(uInphak==="FREEROLE") && (
				<strong>{uBahasaObj["hakuser_"+(uInphak||"freerole").toLowerCase()]||"UNDF"}</strong>
			)}
		</CCol>
		</CRow>

		{(parseInt(uIDselect) <= 0) && (
		<>
			{uDatacabangArr.length > 1 && (
			<CRow className="my-2">
			<CCol xs="4" className="text-md-end">
				<CLink
					onClick={()=>{setInpCabangselectAll(!uInpCabangselectAll);hdlKlikCabangaksesall()}}
					className="classcursorpointer">
				{uBahasaObj.caption_aksescabang||"Akses Cabang"}
				</CLink>
			</CCol>
			<CCol className="">
				<CInputGroup size="sm">
				{vCabangAllowArr.map((vItems,vKeys)=>{
				return (
					<CInputGroupText
						key={vKeys}
						className="p-0 ps-2 pt-1 me-3 mb-2 bg-light text-dark"
						style={{minWidth:"150px !important",width:"auto"}}>
					<CFormCheck inline size="sm"
						value={vItems.id}
						id={"inpcabangid"+vItems.id} 
						label={vItems.cabang_nama}
						onChange={(e)=>hdlChangeCabangakses(e)}
						checked={uInpCabangIDArr.includes(vItems.id)}
						className="classcursorpointer"/>
					</CInputGroupText>
				)
				})}
				</CInputGroup>
			</CCol>
			</CRow>
			)}
		</>
		)}
		</CForm>
		)
	}
	//--END CONTENT--/

	//--API--/
	const apiLoaddata = () => {
		setHtml500msg("");

		setJmlData(0);
		setDatamainArr([]);
		setIDselect(0);
		setRowselect(-1);
		setPageActive(1);
		setDataroleArr(uRoledefault);
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vKeyword 	= uKeywordObj.kwd_user || "";
		const vDATAS	= JSON.stringify({
			send_firstload : uFirstLoad,
			send_keyword : vKeyword,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_user/ld_data";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.tampil) {
				const vTmpObj = JSON.parse(output_string.dataobject||"[]");
				setDatamainArr(vTmpObj);
				setJmlData((parseInt(vTmpObj.length)||0));

				setDataroleArr(JSON.parse(output_string.datarole||"[]"));
				if(uFirstLoad==="YA") {
					setFirstLoad("TIDAK");
				}

				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				setHtml500msg(output_string.info);
			} else if(output_string.errors) {
				console.log("(User - apiLoaddata) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				setHtml500msg("<font color='red'>"+vMsg+"</font>");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(User - apiLoaddata) catch-error : "+error);
			setHtml500msg(pjson.mydefault.msg500str.join(" "));
		});
	}
	const apiProsessimpan = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;

		const vBtnsimpan= document.getElementById("btnDialogSimpan");
		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		vBtnsimpan && (vBtnsimpan.disabled = true)
		setLoading(true)

		const vCabangfilterArr	= uDatacabangArr.filter(vItems => {
			return uInpCabangIDArr.includes(vItems.id)
		});
		const vCabanginputArr		= vCabangfilterArr.map(vItems => {
			return {cabang_id:vItems.id,cabang_caption: vItems.cabang_nama}
		});

		/*//--TESTING_FRONTEND--/
		console.log("(User - apiProsessimpan) vCabangfilterArr "+JSON.stringify(vCabangfilterArr)); 
		let vTimeout = setTimeout(()=>{
			setLoading(false);
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_usercaption : uInpusercaption,
			send_username : uInpusername,
			send_passwd1 : uInppasswd1,
			send_passwd2 : uInppasswd1,
			send_hak : uInphak,
			send_cabangidlist : uInpCabangIDArr.join(","),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_user/pr_simpan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			vBtnsimpan && (vBtnsimpan.disabled = false)
			if(output_string.sukses) {
				if(_IDTABEL <= 0) {
					const vTmpObj = {
						id: output_string.tabelid||255,
						tgl_input: "",
						userinput: uTokenObj.usercaption,
						onlinestatus:false,
						username:uInpusername,
						usercaption:uInpusercaption,
						cabang_arr:vCabanginputArr,
						hak: uInphak
					}
					setDatamainArr([vTmpObj,...uDatamainArr]);
					setJmlData(uJmlData+1);

					initFormAwal();
					setPageActive(1);
					setRowselect(-1);
					showToast((uBahasaObj.pesan_sukses || "Data Berhasil Disimpan.."),"SUKSES");
				} else {
					hdlToggledlg();

					uDatamainArr[vIdxObj].usercaption 	= uInpusercaption;
					uDatamainArr[vIdxObj].hak 			= uInphak;
					uDatamainArr[vIdxObj].username 		= uInpusername;
				}
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(User - apiProsessimpan) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setShowForm(false); setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			vBtnsimpan && (vBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(User - apiProsessimpan) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProseshapus = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_user/pr_hapus";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr.splice(vIdxObj,1);
				setRowselect(-1);
				setJmlData((parseInt(uDatamainArr.length)||0));
			
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(User - apiProseshapus) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(User - apiProseshapus) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiProsesonline = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0;
		if(_IDTABEL <= 0) return;

		const vIdxObj	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL)
		setLoading(true)

		/*//--TESTING_FRONTEND--/
		let vTimeout = setTimeout(()=>{
		},2000); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_tabelid : _IDTABEL,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_user/pr_offline";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) { setSessionaktif(false);return response.json(); } })
		.then((output_string) => {
			setSessionaktif(true);
			setLoading(false);
			if(output_string.sukses) {
				uDatamainArr[vIdxObj].onlinestatus = false;
				setEfekview(!uEfekview);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(User - apiProsesonline) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			setLoading(false);
			console.log("(User - apiProsesonline) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END API--/

	React.useEffect(()=>{
		/*if(uTokenObj.userhak!=="FREEROLE" && uTokenObj.userhak!=="ADMIN") {
			uNavigate("/a1profile"); return;
		}//-*/
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
    	apiLoaddata();

    	return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});
			setShowForm(false);
    		setDatamainArr([]);
    		setDataroleArr([]);
    	}
	},[])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		/*const vKeyword = uKeywordObj.kwd_user || "";
		if(vKeyword.length <= 2) {
			uElJmlData && (uElJmlData.innerHTML = "&#8734;");
			return;
		}//-*/
		uElJmlData && (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	},[uJmlData])
	React.useEffect(()=>{ setRowselect(-1); },[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		const vKeyword = (uKeywordObj.kwd_user||"").trim();
		if(vKeyword === "") setFirstLoad("YA");

		apiLoaddata();
	},[uHandelView])
	React.useEffect(()=>{
		//console.log("User - [uHandelTambah] 1 => "+uHandelTambah);
		if(!uHandelTambah) return;

		uHeaderActionObj.isHeaderTambah = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		/*if(uDatacabangArr.length === 1) {
			const vCabangIDinit = localStorage.getItem("lastcabangid") || "0";
			setInpCabangIDArr([vCabangIDinit]);
		}//-*/
		setHeaderdlg((uBahasaObj.word_tambah||"Tambah")+" "+uBahasaObj["menus_"+uActiveroute.toLowerCase()]);
		setShowForm(true);
	},[uHandelTambah])
	React.useEffect(()=>{
		if(!isShowForm) { initFormAwal(); return; }

		//console.log("("+cComponentName+"[isShowForm]) isShowForm 1: "+isShowForm);
		let vTimeout = setTimeout(function(e){
			clearTimeout(vTimeout);

			const vElFocus = document.getElementById("inpusercaption");
			if(!isMobile) vElFocus && vElFocus.focus();
		},200);
	},[isShowForm])
	React.useEffect(()=>{
		if(!isShowForm) return;
		if(uDatacabangArr.length > 1) return;
		if(uInpCabangIDArr.length > 0) return;

		const vCabangIDinit = localStorage.getItem("lastcabangid") || "0";
		setInpCabangIDArr([vCabangIDinit]);
	},[isShowForm,uInpCabangIDArr])
	//console.log("User - uPageActive => "+JSON.stringify(uPageActive));

	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return ""; }
	if(uHtml500msg)  return (
		<CCard className="classbgcard">
		<CCardHeader>
		<strong>{uBahasaObj.caption_page500||"Page 500"}</strong>
		</CCardHeader>
		<CCardBody>{UFunc.renderHTML(uHtml500msg)}</CCardBody>
		</CCard>
	)

	return (
		<>
		<CCard className="classbgcard">
			<CCardHeader className="d-flex justify-content-between align-items-center">
			<b>{uBahasaObj.caption_listdata||"List Data"}</b>
			
			<div>
				<small className="d-md-none text-primary">[{UFunc.formatAngka(uJmlData)}]</small>
				<span className="d-md-none mx-1">&middot;</span>
				<span className="text-muted classfontsmaller">
					<small>{UFunc.formatAngka(uTimeelapse)}ms</small>
				</span>
			</div>
			</CCardHeader>

			<CCardBody className="px-0">{contentMain()}</CCardBody>
			
			{(uJmlData > uMaxData) && (
			<CCardFooter>
				<MyPagination 
					activePage={uPageActive}
					pages={uJmlHal}
					onActivePageChange={(i)=>setPageActive(i)}
					align="start"/>
			</CCardFooter>
			)}
		</CCard>

		<MyDialogform
			options={{size:"lg",centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={isShowForm} 
			dialogHeader={uHeaderdlg}
			toggle={hdlToggledlg}
			renderContent={contentDlgform()}/>
		</>
	)
}	

export default User
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CRow,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CFormSelect,
	CButton,
	CImage,
	CCard,
	CCardHeader,
	CCardBody,
	CCardFooter,
	CSpinner,
	CLink,
	CDropdown,CDropdownItem,CDropdownToggle,CDropdownMenu,
	CTooltip,
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { isMobile } from "react-device-detect";
import {
	MyDialogform,
	MyDialogview,
	MyPagination,
} from '../components/index'
import { Konfirm } from '../helpers/onConfirm'
import { UFunc,cAngkaTgl,cBulanPendek,cBulanPanjang } from '../helpers/functions'

const pjson 	= require('../../package.json')
const Lappiutang= (props) => {
	const {
		setLoading,showToast,prosesExpired,setSessionaktif
	} = props; 

	//--DOM--/
	const uNavigate 			= useNavigate();
	const uDispatch 			= useDispatch();
	//--END DOM--/

	const uBahasaObj  				= useSelector(state => state.listBahasa);
	const uActiveroute  			= useSelector(state => state.activeRoute);
	const uSettingObj 				= useSelector(state => state.gListUserSetting);
	const uIsScrollBottom			= useSelector(state => state.gIsScrollBottom);
	const uSettingsistemObj			= useSelector(state => state.gSystemsetting);
	const uEfekapp					= useSelector(state => state.effectApp);
	const [uEfekview,setEfekview] 	= React.useState(false);
	const uMaxData					= isMobile ? (uSettingObj.jml_mobile || 25) : (uSettingObj.jml_laporan || 100);
	const uTokenObj					= JSON.parse(localStorage.getItem("token")||"{}");
	const uSystemsettingObj			= useSelector(state => state.gSystemsetting);
	const uSelisihrangetanggal 		= useSelector(state => state.gSelisihrangetanggal);
	
	const uHeaderActionObj  		= useSelector(state => state.gInitHeaderAction);
	const uHandelView 				= uHeaderActionObj.isHeaderView || false;
	const uHandelReset 				= uHeaderActionObj.isHeaderreset || false;

	//--VIEWS_VARS--/
	const [uHtml500msg,setHtml500msg]	= React.useState(); 
	const uIsHapusallow					= (uTokenObj.userhak==="FREEROLE"||uTokenObj.userhak==="ADMIN") ? true : false;
	const [uIsDataloaded,setDataloaded]	= React.useState(false); 
	const [uCaptionObj,setCaptionObj]	= React.useState({}); 
	const [uFilterObj,setFilterObj]		= React.useState({});
	const [uFirstload,setFirstload]		= React.useState("YA");
	const [uSortedObj,setSortedObj]		= React.useState({});
	const [uIsDetilset,setDetilset]		= React.useState(false); 
	//const uFilterObj		  		= useSelector(state => state.gFilterObj) || {};

	const [uTimeelapse,setTimeelapse]	= React.useState(0); 
	const [uJmlData,setJmlData]			= React.useState(0);
	const [uPageActive,setPageActive]	= React.useState(1);
	const [uJmlHal,setJmlHal]			= React.useState(1);
	const [uRowselect,setRowselect]		= React.useState(-1);
	const [uDataselectID,setDataselectID]= React.useState(0);

	const uDatacabangArr				= JSON.parse(localStorage.getItem("cabanglist")||"[]")
	const [uDatadefaultArr,setDatadefaultArr]= React.useState([]);
	const [uDatamainArr,setDatamainArr]	= React.useState([]);
	const [uDatadetilArr,setDatadetilArr]= React.useState([]); 
	const [uDatasumArr,setDatasumObj]	= React.useState({});
	//--END VIEWS_VARS--*/

	//--FORM_VARS--/
	const [uIsDlgformshow,setDlgformshow]	= React.useState(false);
	const [uHeaderDlgform,setHeaderDlgform]	= React.useState("");
	const [uFormdataObj,setFormdataObj]		= React.useState({});
	//--END FORM_VARS--/

	//--FORM_VARS--/
	const [uISDlgviewshow,setDlgviewshow]	= React.useState(false);
	const [uDlgviewdataObj,setDlgviewdataObj]= React.useState({});
	//--END FORM_VARS--/

	//--DOM_VARS--/
	const uElJmlData					= document.getElementById("idjmldata");
	const [uElBtnsimpan,setElBtnsimpan]	= React.useState(document.getElementById("btnDialogSimpan"));
	const fileType 		= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	//--END DOM_VARS--/

	//--SETTING_CETAK--/
	const uHTMLstylelaporan		= useSelector(state => state.gHTMLstyleLaporan);
	const uFrameExport 			= document.getElementById("ifmcontentstoprint");
	//--END SETTING_CETAK--/

	//--HANDEL--/
	const hdlToggleDlgform = () => { setDlgformshow(false) }
	const hdlKlikDlgsimpan = () => {
		//--VALIDASI_FILTER--/
		let vElfocus	= document.getElementById("inpcabangid");
		if(parseInt(uFormdataObj.cabang_id||"0") <= 0) {
			vElfocus && vElfocus.focus();
			showToast("Cabang Harus Dipilih !");
			return
		}

		vElfocus		= document.getElementById("inptglawal");
		const vTglawal 	= uFormdataObj.tgl_awal;
		if(!UFunc.isDateValid(vTglawal)) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Awal Laporan Tidak Valid !"); return
		}
		const vTglawalDT	= UFunc.toDate(vTglawal);
		if(UFunc.DateIsBeyond(vTglawalDT)) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Awal Laporan Melewati Tanggal SAAT INI ! "); return
		}

		vElfocus		= document.getElementById("inptglakhir");
		const vTglakhir	= uFormdataObj.tgl_akhir;
		if(!UFunc.isDateValid(vTglawal)) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Akhir Laporan Tidak Valid !"); return
		}
		const vTglakhirDT	= UFunc.toDate(vTglakhir);
		const vTglnowDT		= new Date();
		const vTglbatasDT	= new Date(vTglnowDT.getFullYear(),vTglnowDT.getMonth()+1,0);

		//alert(vTglbatasDT+"<br/>"+vTglakhirDT);return;
		if(UFunc.SelisihHari(UFunc.DbDate(vTglbatasDT),UFunc.DbDate(vTglakhirDT)) > 0) {
			vElfocus && vElfocus.focus();
			showToast("Tanggal Akhir Laporan Melewati Tanggal BULAN INI ! "); return
		}

		if(UFunc.SelisihHari(UFunc.DbDate(vTglawalDT),UFunc.DbDate(vTglawalDT)) > uSelisihrangetanggal) {
			vElfocus && vElfocus.focus();
			showToast("Maksimal Selisih Range Tanggal adalah "+uSelisihrangetanggal+" hari.."+
				"<br/>Mohon Diperbaiki !"+
				""); return
		}
		//alert(vTanggalDT);return;
		//--END VALIDASI_FILTER--/

		setDataloaded(false);
		apiLoadall();
	}
	const hdlKlikDetilpiutang = (_IDTABEL) => {
		_IDTABEL = parseInt(_IDTABEL)||0; if(_IDTABEL<=0) return;

		setDataselectID(_IDTABEL);
		const vArrIdx 	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL,"id_pasien");
		const vRowidx	= UFunc.getTabelRowIndeks("idtabeldata","idtr"+_IDTABEL);
		setRowselect(vRowidx);

		uDlgviewdataObj.header 	= "Detil Piutang: "+(uDatamainArr[vArrIdx].nama_pasien||"").toUpperCase();
		const vDatadetilArr 	= uDatamainArr[vArrIdx].detil_arr || [];
		if(vDatadetilArr.length > 0) {
			uDlgviewdataObj.detil_arr = vDatadetilArr;
			setDlgviewshow(true);
			return;
		}

		apiLoadpasien(_IDTABEL);
	}
	const hdlKlikSort = (_NAMAKOL) => {
		_NAMAKOL		= (_NAMAKOL||"").toUpperCase();
		const vNamenow	= (uSortedObj.nama||"").toUpperCase();
		const vSortnow	= (uSortedObj.jenis||"").toUpperCase();

		const vSortedObj		= uSortedObj;
		vSortedObj.init_aktif 	= "YA";
		if(vNamenow==="" || vNamenow !== _NAMAKOL) {
			vSortedObj.nama 	= _NAMAKOL.toLowerCase();
			vSortedObj.jenis 	= "ASCENDING";
		} else if(vNamenow===_NAMAKOL) {
			if(vSortnow==="ASCENDING")
				vSortedObj.jenis = "DESCENDING";
			else if(vSortnow==="DESCENDING") {
				 vSortedObj.nama 	= "";
				 vSortedObj.jenis 	= "";
			}
		}
		vSortedObj.init_aktif 	= undefined;

		setSortedObj(vSortedObj);
		setEfekview(!uEfekview);
	}
	const hdlKlikCetak = () => {
		if(uDatamainArr.length <= 0) return;

		const vContent 	= contentCetak();
		if(vContent === "") return
		//console.log("(Lappiutang - hdlKlikCetak) vContent = "+vContent)

		var pri 		= uFrameExport.contentWindow;
		pri.document.open();
		pri.document.write(vContent);
		pri.document.close();
		pri.window.focus();
		pri.window.print();
	}
	const hdlKlikExcel = () => {
		if(uDatamainArr.length <= 0) return;

		contentExcelall();
	}
	//--END HANDEL--/

	//--INIT--/
	const initResettampilan = () => {
		setCaptionObj({});
		setDatamainArr([]);
		setDataselectID(0); setRowselect(-1); setPageActive(1);
		setFilterObj({}); setSortedObj({});
	}
	const initJmldata = () => {
		uElJmlData 
			&& (uElJmlData.innerHTML = UFunc.formatAngka(uJmlData));
	}
	//--END INIT--/

	//--CONTENT--/
	const contentDlgform = () => {
		if(!uIsDlgformshow) return (<></>)

		const vJmlTahunlaporan	= parseInt(uSettingsistemObj.jml_tahun_laporan)||5;
		const vCabangID 		= uFormdataObj.cabang_id || 0;
		const vCabangviewArr	= uTokenObj.userhak==="FREEROLE" ? uDatacabangArr
			: uDatacabangArr.filter(vItems=>(uTokenObj.user_cabangarr || []).includes(vItems.id.toString()))
		const vTahunnow			= (new Date()).getFullYear();

		return (
		<CForm>
		{uDatacabangArr.length > 1 && (
		<CRow className="my-2">
		<CCol xs="3" md="4">Cabang</CCol>
		<CCol className="text-end">
		<CFormSelect size="sm"
			value={uFormdataObj.cabang_id||0}
			onChange={(e)=>{uFormdataObj.cabang_id = e.target.value; setEfekview(!uEfekview)}}
			id="inpcabangid">
			{vCabangviewArr.map((vItems,vKeys)=>{
				const {
					id,cabang_nama
				} = vItems

				return (
				<option value={id} key={vKeys}>{(cabang_nama||"UNDF").toUpperCase()}</option>
				)
			})}
			</CFormSelect>
		</CCol>
		</CRow>
		)}

		<CRow className="my-2">
		<CCol xs="3" md="4">Tanggal</CCol>
		<CCol className="text-end">
			<CInputGroup size="sm">
			<CFormInput size="sm" type="date"
				value={uFormdataObj.tgl_awal}
				onChange={(e)=>{uFormdataObj.tgl_awal=e.target.value;setEfekview(!uEfekview)}}
				id="inptglawal"/>
			<CInputGroupText>s.d.</CInputGroupText>
			<CFormInput size="sm" type="date"
				value={uFormdataObj.tgl_akhir}
				onChange={(e)=>{uFormdataObj.tgl_akhir=e.target.value;setEfekview(!uEfekview)}}
				onKeyDown={(e)=>UFunc.hdlKeyDownInput(e,"btnDialogSimpan")}
				id="inptglakhir"/>
			</CInputGroup>
		</CCol>
		</CRow>
		</CForm>
		)
	}
	const contentDlgview = () => {
		if(!uISDlgviewshow) return (<></>);

		const vDatadetilArr	= uDlgviewdataObj.detil_arr || [];
		let vNumber			= 0;
		const vDatasumObj	= {};

		return (
		<CCard className="classbgcard">
		<CCardBody className="px-0">
		<table 
			className="table table-borderless table-striped table-hover" id="idtabeldata">
		<thead>
			<tr className="d-none d-lg-table-row text-center align-top classfontsmaller">
			<th width="5%" className="px-1"><small>No</small></th>
			
			<th className="px-1">
				<small>
				<CRow className="mx-0">
				<CCol lg="2" className="text-start">{uBahasaObj.caption_tglperiksa||"Tgl Periksa"}</CCol>
				<CCol lg="4" className="text-start">{uBahasaObj.word_dokter||"Dokter"}</CCol>
				<CCol lg="2" className="">{uBahasaObj.caption_nilaitransaksi||"N.Transaksi"} (Rp)</CCol>
				<CCol lg="2" className="">{uBahasaObj.caption_nilaibayar||"Nilai Bayar"} (Rp)</CCol>
				<CCol lg="2" className="">
					{uBahasaObj.caption_nilaisisa||"Sisa Piutang"} (Rp)
				</CCol>
				</CRow>
				</small>
			</th>
			</tr>

			<tr className="d-lg-none">
			<th width={25} className="p-0"/>
			<th className="p-0"/>
			</tr>
		</thead>

		<tbody>
		{vDatadetilArr.map((vItems,vKeys)=>{
			const {
				id,dokter_caption,nilai_transaksi,
				nilai_bayar,nilai_piutang,tgl_periksa
			} = vItems;

			vNumber++;
			vDatasumObj.nilai_transaksi = parseFloat(vDatasumObj.nilai_transaksi||0) + parseFloat(nilai_transaksi);
			vDatasumObj.nilai_bayar = parseFloat(vDatasumObj.nilai_bayar||0) + parseFloat(nilai_bayar);
			vDatasumObj.nilai_piutang = parseFloat(vDatasumObj.nilai_piutang||0) + parseFloat(nilai_piutang);

			return (
			<tr id={"idtr"+id} className={"align-top text-end classfontsmaller "+(uRowselect===vKeys?"classrowselect":"")}
				 key={vKeys}>
			<td className="px-1">{vNumber}.</td>
			<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="12" lg="2" className="px-1 text-start">
					<span className="d-lg-none me-1">Tanggal:</span>
					{UFunc.HariAngka(tgl_periksa)}
				</CCol>

				<CCol xs="12" className="px-1 my-3 d-lg-none classborderbottom"/>

				<CCol xs="4" className="px-1 d-lg-none text-start">Dokter</CCol>
				<CCol xs="8" lg="4" className="text-lg-start px-1">
					<b>{(dokter_caption||"UNDF")}</b>
				</CCol>

				<CCol xs="5" className="px-1 d-lg-none text-start">Nilai Transaksi (Rp)</CCol>
				<CCol xs="7" lg="2" className="px-1">{UFunc.formatAngka(nilai_transaksi)}</CCol>
				
				<CCol xs="5" className="px-1 d-lg-none text-start">Nilai Bayar (Rp)</CCol>
				<CCol xs="7" lg="2" className="px-1 text-success">{UFunc.formatAngka(nilai_bayar)}</CCol>
				
				<CCol xs="5" className="px-1 d-lg-none text-start">Nilai Piutang (Rp)</CCol>
				<CCol xs="7" lg="2" className="px-1">
					<span className="text-danger">{UFunc.formatAngka(nilai_piutang)}</span>
				</CCol>
				</CRow>
			</td>
			</tr>
			)
		})}
		</tbody>

		<tfoot className="fw-bolder classborderbottom">
			<tr className="d-none d-lg-table-row">
			<td className="px-1">&nbsp;</td>
			<td className="px-1">
				<CRow className="mx-0">
				<CCol xs="6" className="text-center text-capitalize px-1">{uBahasaObj.word_jumlah||"JUMLAH"}</CCol>
				<CCol lg="2" className="text-end px-1 d-none">{UFunc.formatAngka(vDatasumObj.nilai_transaksi)}</CCol>
				<CCol lg="2" className="text-end px-1 d-none">{UFunc.formatAngka(vDatasumObj.nilai_bayar)}</CCol>
				<CCol xs="6" lg="2" className="text-end px-1">{UFunc.formatAngka(vDatasumObj.nilai_piutang)}</CCol>
				</CRow>
			</td>
			</tr>
		</tfoot>
		</table>
		</CCardBody>
		</CCard>
		)
	}
	const contentLaporan = () => {
		if(!uIsDataloaded) return (<></>)

		//console.log("(Lappiutang - contentLaporan) uFilterObj.pasien_id => "+uFilterObj.pasien_id);
		if(uJmlData <= 0) return (
			<div className="mx-md-5 px-lg-5">
			&middot;&middot; {uBahasaObj.caption_dataempty||"Data Tidak Ada"} &middot;&middot;
			</div>
		)

		const vMin			= (uPageActive-1)*uMaxData;
		const vMax			= (vMin+uMaxData);
		let vNumber			= (vMin);

		const vStylekolfixed = {
			position: "sticky",
			left: 0,
			background: "#f0f0f0",
			zIndex: 1,
			borderRight:"1px solid #DCDCDC",
		}
		const vWidthtabel = 900;

		const vDataviewArr	= uDatamainArr.slice(vMin,(vMax));
		
		return (
		<div style={{overflowX:"auto"}}
			className=""
			id="iddivtabel">
		<table 
			style={{minWidth:vWidthtabel,width:"100%"}}
			className="table table-borderless table-striped table-hover" 
			id="idtabeldata">
		<thead>
			<tr className="d-none"><th colSpan={7}>{vMin} - {vMax}</th></tr>
			<tr className="text-center align-top classfontsmaller">
			<th width={40} className="px-1 border" style={vStylekolfixed}>No</th>
			<th className="px-1 text-start border" style={{...vStylekolfixed,left:40}}>
				{uBahasaObj.caption_namapasien||"Nama Pasien"}
			</th>
			<th width={150} className="px-1 border">{uBahasaObj.caption_nilaisebelum||"N.Sebelum"} (Rp)</th>
			<th width={135} className="px-1 border">{uBahasaObj.caption_piutangbaru||"Piutang Baru"} (Rp)</th>
			<th width={135} className="px-1 border">{uBahasaObj.caption_bayarblnini||"Nilai Bayar"} (Rp)</th>
			<th width={170} className="px-1 border">
				<span className={"me-1"+((uSortedObj.nama||"")==="nilai_piutang" ? " text-danger" : "")}>
				{uBahasaObj.caption_sisa||"Sisa Piutang"} (Rp)
				</span>
				<CLink className={"classcursorpointer text-black"}
					onClick={()=>hdlKlikSort("nilai_piutang")}>
				<CIcon icon={((uSortedObj.nama||"")==="nilai_piutang" && (uSortedObj.jenis||"")==="DESCENDING" ? "cilArrowBottom" : "cilArrowTop")}
					className="align-middle" height={20}/>
				</CLink>
			</th>
			</tr>
		</thead>

		<tbody>
		{vDataviewArr.map((vItems,vKeys) => {
			const {
				nama_pasien,nilai_piutang,nilai_sebelum,
				nilai_bayar,nilai_baru,id_pasien,is_loading
			} = vItems;

			vNumber++;
			return (
			<tr className={"align-top text-end"+(uRowselect===vKeys?" classrowselect":"")}
				 id={"idtr"+id_pasien}
				 key={vKeys}>
			<td className="px-1" style={vStylekolfixed}>{vNumber}.</td>
			<td className="px-1 text-start d-md-flex justify-content-between" 
				style={{...vStylekolfixed,left:40}}>
				<span className="text-info">{nama_pasien||("["+id_pasien+"]")}</span>

				{(is_loading||false)===true ? (
					<CSpinner color="primary" className="mx-2 align-middle" size="sm"/>
				) : (
				<CTooltip content={"Lihat Detil Piutang: "+(nama_pasien||"").toUpperCase()}>
					<CLink className="classcursorpointer"
						onClick={()=>hdlKlikDetilpiutang(id_pasien)}>
					<CIcon icon="cilFindInPage" className="classikonbox" height={23}/>
					</CLink>
				</CTooltip>
				)}
			</td>

			<td className="px-1"><b>{UFunc.formatAngka(nilai_sebelum)}</b></td>
			<td className="px-1">{UFunc.formatAngka(nilai_baru)}</td>
			<td className="px-1 text-success">{UFunc.formatAngka(nilai_bayar)}</td>
			<td className="px-1">
				<span className="text-danger">{UFunc.formatAngka(nilai_piutang)}</span>
			</td>
			</tr>
			)
		})}
		</tbody>

		<tfoot className="fw-bolder bg-primary text-white">
			<tr>
			<td colSpan={2} className="text-center text-capitalize text-dark" style={vStylekolfixed}>{uBahasaObj.word_jumlah||"JUMLAH"}</td>
			<td className="px-1 text-end">{UFunc.formatAngka(uDatasumArr.nilai_sebelum)}</td>
			<td className="px-1 text-end">{UFunc.formatAngka(uDatasumArr.nilai_baru)}</td>
			<td className="px-1 text-end">{UFunc.formatAngka(uDatasumArr.nilai_bayar)}</td>
			<td className="px-1 text-end">{UFunc.formatAngka(uDatasumArr.nilai_piutang)}</td>
			</tr>
		</tfoot>
		</table>
		</div>
		)
	}
	const contentCetak = () => {
		if(uDatamainArr.length <= 0) return "";
		//console.log("(Lappiutang - contentCetak) vHTMLs => "+vHTMLs)

		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		const vArrIdx		= UFunc.getObjectIndeks(uDatamainArr,uFilterObj.pasien_id,"id_pasien");
		const vPasiencaption= vArrIdx >= 0 ? uDatamainArr[vArrIdx].nama_pasien : "";

		const vDatasourceArr= parseInt(uFilterObj.pasien_id||"0") <= 0 ? uDatamainArr
			: (uDatamainArr[vArrIdx].detil_arr||[]);

		const vColspan		= 6;

		let vHTMLs 	= uHTMLstylelaporan;
		vHTMLs += `
			<table id="idtabelhtml">
			<thead>
			<tr>
			<td colspan="`+vColspan+`">
			<div><b>`+(uBahasaObj["menus_"+uActiveroute.toLowerCase()]||"Laporan").toUpperCase()+`</b></div>
			<div>`+uCaptionObj.title+`</div>`+
			(uDatacabangArr.length > 1 
				? (`<div>Cabang: `+vCabangtext+`</div>`) 
				: ``)+
			(parseInt(uFilterObj.pasien_id||"0")>0 ? "<div>Pasien: <b>"+vPasiencaption+"</b></div>" : "")+`
			</td>
			</tr>
			<tr class="idtabelheader">
			`;
		if(parseInt(uFilterObj.pasien_id||"0") > 0)
			vHTMLs += `
				<th width="5%">No</th>
				<th width="10%" align="left">`+(uBahasaObj.caption_tglperiksa||"Tgl Periksa")+`</th>
				<th align="left">`+(uBahasaObj.word_dokter||"Dokter")+`</th>
				<th width="15%">`+(uBahasaObj.caption_nilaitransaksi||"N.Transaksi")+` (Rp)</th>
				<th width="13%">`+(uBahasaObj.word_terbayar||"Terbayar")+` (Rp)</th>
				<th width="15%">`+(uBahasaObj.caption_nilaisisa||"Nilai Sisa")+` (Rp)</th>
			`;
		else
			vHTMLs += `
				<th width="7%">No</th>
				<th align="left">`+(uBahasaObj.caption_namapasien||"Nama Pasien")+`</th>
				<th width="15%">`+(uBahasaObj.caption_nilaisebelum||"N.Sebelum")+` (Rp)</th>
				<th width="13%">`+(uBahasaObj.caption_piutangbaru||"Piutang Baru")+` (Rp)</th>
				<th width="13%">`+(uBahasaObj.caption_nilaibayar||"N.Bayar")+` (Rp)</th>
				<th width="15%">`+(uBahasaObj.caption_nilaisisa||"Nilai Sisa")+` (Rp)</th>
			`;
		vHTMLs += `
			</tr>
			</thead>
			<tbody>`;
		vDatasourceArr.forEach((vItems,vKeys)=>{
			const {
				id_pasien,nama_pasien,dokter_caption,
				nilai_transaksi,nilai_piutang,
				nilai_sebelum,nilai_baru,nilai_bayar,
				tgl_periksa,
			} = vItems;

			if(parseInt(uFilterObj.pasien_id||"0") > 0)
				vHTMLs 	+= `
				<tr key={vKeys} valign="top">
					<td align="right" style="font-size:smaller">`+(vKeys+1)+`.</td>
					<td align="left" style="font-size:smaller">`+UFunc.TglAngka(tgl_periksa)+`</td>
					<td align="left">`+(dokter_caption||"")+`</td>
					<td align="right">`+UFunc.formatAngka(nilai_transaksi)+`</td>
					<td align="right">`+UFunc.formatAngka(nilai_bayar)+`</td>
					<td align="right">`+UFunc.formatAngka(nilai_piutang)+`</td>
				</tr>
				`;
			else
				vHTMLs 	+= `
				<tr key={vKeys} valign="top">
					<td align="right" style="">`+(vKeys+1)+`.</td>
					<td align="left" style="font-weight:bolder">`+(nama_pasien||"UNDF")+`</td>
					<td align="right">`+UFunc.formatAngka(nilai_sebelum)+`</td>
					<td align="right">`+UFunc.formatAngka(nilai_baru)+`</td>
					<td align="right">`+UFunc.formatAngka(nilai_bayar)+`</td>
					<td align="right">`+UFunc.formatAngka(nilai_piutang)+`</td>
				</tr>
				`;
		});
		vHTMLs += `
			</tbody>
			<tfoot>
			<tr>
			`;
		if(parseInt(uFilterObj.pasien_id||"0") > 0)
			vHTMLs += `
			<td colspan="`+(vColspan-3)+`" align="center">JUMLAH</td>
			<td align="right">`+UFunc.formatAngka(uDatasumArr.nilai_transaksi)+`</td>
			<td align="right">`+UFunc.formatAngka(uDatasumArr.nilai_bayar)+`</td>
			<td align="right">`+UFunc.formatAngka(uDatasumArr.nilai_piutang)+`</td>
			`;
		else
			vHTMLs += `
			<td colspan="`+(vColspan-4)+`" align="center">JUMLAH</td>
			<td align="right">`+UFunc.formatAngka(uDatasumArr.nilai_sebelum)+`</td>
			<td align="right">`+UFunc.formatAngka(uDatasumArr.nilai_baru)+`</td>
			<td align="right">`+UFunc.formatAngka(uDatasumArr.nilai_bayar)+`</td>
			<td align="right">`+UFunc.formatAngka(uDatasumArr.nilai_piutang)+`</td>
			`;
		vHTMLs += `
			</tr>
			</tfoot>
			</table>
		`;
		return vHTMLs		
	}
	const contentExcelall = () => {
		const vCabangIdx	= UFunc.getObjectIndeks(uDatacabangArr,uFilterObj.cabang_id);
		const vCabangtext	= vCabangIdx>=0 ? uDatacabangArr[vCabangIdx].cabang_nama : "UNDF";

		const vColspan		= 6;

		let vDatasetsArr 	= [];
		let vRowcount		= 0;
		let vColposwidth	= 50;

		let vTmpObj	= {
			no : "",
			nama_pasien: "",
			nilai_sebelum: "",
			nilai_baru: "",
			nilai_bayar: "",
			nilai_piutang: "",
		};
		vDatasetsArr.push(vTmpObj);

		vRowcount++;
		uDatamainArr.map((vItems,vKeys)=>{
			const {
				id_pasien,nama_pasien,dokter_caption,
				nilai_transaksi,nilai_piutang,
				nilai_sebelum,nilai_baru,nilai_bayar,
				tgl_periksa,
			} = vItems;

			vRowcount++;
			vColposwidth = (nama_pasien||"").length > vColposwidth 
				? (nama_pasien||"").length : vColposwidth; 

			vTmpObj	= {
				no : {v:vKeys+1,t:"n"},
				nama_pasien: (nama_pasien||"Undf Pasien"),
				nilai_sebelum: { v:parseFloat(nilai_sebelum), t:"n", s:{numFmt: "#,##0"} },
				nilai_baru: { v:parseFloat(nilai_baru), t:"n", s:{numFmt: "#,##0"} },
				nilai_bayar: { v:parseFloat(nilai_bayar), t:"n", s:{numFmt: "#,##0"} },
				nilai_piutang: { v:parseFloat(nilai_piutang), t:"n", s:{numFmt: "#,##0"} },
			}
			vDatasetsArr.push(vTmpObj);
		});

		//--SUM_NILAI--/
		vTmpObj	= {
			no : {v: "JUMLAH",t:"s",s:{alignment:{horizontal:"center"},font:{bold:true} }},
			nama_pasien: "",
			nilai_sebelum: { v:parseFloat(uDatasumArr.nilai_sebelum)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_baru: { v:parseFloat(uDatasumArr.nilai_baru)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_bayar: { v:parseFloat(uDatasumArr.nilai_bayar)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
			nilai_piutang: { v:parseFloat(uDatasumArr.nilai_piutang)||0, t:"n", s:{numFmt: "#,##0",font:{bold:true} } },
		};
		vDatasetsArr.push(vTmpObj);
		vRowcount++;
		//--END SUM_NILAI--/

		const wsrows	= [{hpt:43}]
		const wscols	= [
			{wpx:40},
			{wch:vColposwidth},
			{wpx:130},
			{wpx:100},
			{wpx:100},
			{wpx:150},
		];
		
		const wsmerge	= [
			{s:{r:0,c:0},e:{r:0,c:vColspan - 1}},
			{s:{r:vRowcount,c:0},e:{r:vRowcount,c:vColspan - 5}}
		]

		const vTanggalnowDT	= new Date()
		const vNamafile 	= 
			vTanggalnowDT.getFullYear()+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+
			UFunc.leadZero(vTanggalnowDT.getDate())+"_LAPPIUTANG_"+
			(uFilterObj.tgl_awal||"").replace(/-/g,"")+"_"+
			(uFilterObj.tgl_akhir||"").replace(/-/g,"")+
		"";
		const vSheetlabel = 
			(uFilterObj.tgl_awal||"").replace(/-/g,"")+"_"+
			(uFilterObj.tgl_akhir||"").replace(/-/g,"");

		const ws 		= XLSX.utils.json_to_sheet(vDatasetsArr);

		//--CUSTOM_HEADER--/
		const vJudullaporan = uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]+
			"\r\nPeriode "+UFunc.HariAngka(uFilterObj.tgl_awal)+" s.d. "+
			UFunc.HariAngka(uFilterObj.tgl_akhir)+
			(uDatacabangArr.length > 1 ? "\r\nCabang: "+vCabangtext : "" )+
		"";
		const vHeaderArr = [
			{v:"No",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Pasien",s:{alignment:{horizontal:"left"},font:{bold:true}}}, 
			{v:"N.Sebelum (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Piutang Baru (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"N.Bayar (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
			{v:"Sisa Piutang (Rp)",s:{alignment:{horizontal:"center"},font:{bold:true}}}, 
		];

		XLSX.utils.sheet_add_aoa(ws, [[{v:vJudullaporan,t:"s",s:{
			alignment:{horizontal:"center",wrapText: true},
			font:{bold:true} 
		}}]], { origin: "A1" });
		XLSX.utils.sheet_add_aoa(ws, [vHeaderArr], { origin: "A2" });
		//--END CUSTOM_HEADER--*/

		ws['!rows'] 	= wsrows;
		ws['!cols'] 	= wscols;
		ws["!merges"] 	= wsmerge;

		const wb 		= { Sheets: { [vSheetlabel]: ws }, SheetNames: [vSheetlabel] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", bookSST: true, type: "array" });
		const data 		= new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, vNamafile + fileExtension);
	}
	//--END CONTENT--/

	//--REST_API--/
	const apiLoadawal = () => {}
	const apiLoadall = () => {
		const vTmpFilterObj 		= uFormdataObj;
		vTmpFilterObj.init_change 	= undefined;
		uElBtnsimpan && (uElBtnsimpan.disabled = true)
		setLoading(true);

		/*//--TESTING_FRONTEND--/
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			clearTimeout(vTimeout)
			setLoading(false);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_filterlist : JSON.stringify(vTmpFilterObj),
			//send_pg	: _PAGE <= 0 ? 1 : _PAGE,
			//send_maxdata	: uMaxData,
			send_firstload	: uFirstload,//vHandelview,
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lappiutang/ld_laporan";

		setTimeelapse(0);
		const vTimeBegin = new Date();
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			if(output_string.tampil) {
				setFilterObj(vTmpFilterObj);
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]");
				/*//--LOAD_PER_PAGE--/
				vLoadArr.map(vItems=>vItems.pg = parseInt(_PAGE)||1);
				setDatamainArr([...vDatamainArr,...vLoadArr]);
				setJmlData(parseInt(output_string.totaldata||uJmlData));
				//--END LOAD_PER_PAGE--*/

				//--LOAD_ALL_VIEW_PER_PAGE--/
				setDatamainArr(vLoadArr);
				//setJmlData(vLoadArr.length);
				//--END LOAD_ALL_VIEW_PER_PAGE--*/

				setFirstload("TIDAK");
				
				hdlToggleDlgform();
				setDataloaded(true);
				const vTimeEnd = new Date();
				const vTimeDiff = vTimeEnd - vTimeBegin; //in ms
				setTimeelapse(vTimeDiff);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lappiutang - apiLoadall) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			setLoading(false);
			console.log("(Lappiutang - apiLoadall) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	const apiLoadpasien = (_IDTABEL) => {
		_IDTABEL		= parseInt(_IDTABEL)||0; if(_IDTABEL <= 0) return;
		const vArrIdx	= UFunc.getObjectIndeks(uDatamainArr,_IDTABEL,"id_pasien");
		uDatamainArr[vArrIdx].is_loading = true;
		setEfekview(!uEfekview);

		const vTmpFilterObj 		= uFilterObj;
		vTmpFilterObj.pasien_id 	= _IDTABEL;
		vTmpFilterObj.init_change 	= undefined;

		/*//--TESTING_FRONTEND--/
		console.log("vTmpFilterObj.pasien_id => "+vTmpFilterObj.pasien_id );
		let vTmpObj	= []
		let vTimeout = setTimeout(()=>{
			uElBtnsimpan && (uElBtnsimpan.disabled = false)
			clearTimeout(vTimeout)
			setLoading(false);
		},2500); return
		//--END TESTING_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_filterlist : JSON.stringify(vTmpFilterObj),
			send_tokenauth : uTokenObj.userinit
		});
		const vURLs	= pjson.homepage+"api/api_lappiutang/ld_laporan";

		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		}).then((response)=> { if (response.status === 200) {setSessionaktif(false); return response.json();} })
		.then((output_string) => {
			setSessionaktif(true);	
			uDatamainArr[vArrIdx].is_loading = false;
			if(output_string.tampil) {
				const vLoadArr	= JSON.parse(output_string.dataobject||"[]");
				//console.log("(Lappiutang - apiLoadpasien) vLoadArr => "+JSON.stringify(vLoadArr) );

				//--LOAD_ALL_VIEW_PER_PAGE--/
				const vPasienID = parseInt(vTmpFilterObj.pasien_id||"0");
				uDatamainArr[vArrIdx].detil_arr = vLoadArr;
				uDlgviewdataObj.detil_arr 		= vLoadArr;
				setDlgviewshow(true);
				//--END LOAD_ALL_VIEW_PER_PAGE--*/

			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Lappiutang - apiLoadpasien) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
				setLoading(true); prosesExpired();
			}
		})
		.catch((error) =>{
			uDatamainArr[vArrIdx].is_loading = false;
			console.log("(Lappiutang - apiLoadpasien) catch-error : "+error);
			showToast(pjson.mydefault.msg500str.join(" "),"ERROR");
		});
	}
	//--END REST_API--/

	React.useEffect(()=>{
		uDispatch({type: "set", effectApp: !uEfekapp});
		uDispatch({type: "set", gUrlbefore: window.location.pathname + window.location.search});
		
		apiLoadawal();

		return () => {
			uDispatch({type: "set", gInitHeaderAction: {}});

			setDlgformshow(false); setDlgviewshow(false);
			setDatamainArr([]);
			setDataloaded(false)
		}
	},[])
	React.useEffect(()=>{ initJmldata() },[uElJmlData])
	React.useEffect(()=>{
		let vJmlHal	= Math.ceil(uJmlData / uMaxData);
		setJmlHal(vJmlHal);//-*/
		setPageActive(uPageActive<=0?1:uPageActive);

		initJmldata()
	},[uJmlData])
	React.useEffect(()=>{
		const vArrIdx		= UFunc.getObjectIndeks(uDatamainArr,uFilterObj.pasien_id,"id_pasien");
		const vDatasourceArr= parseInt(uFilterObj.pasien_id||0) <= 0
			? uDatamainArr 
			: (vArrIdx > 0 ? (uDatamainArr[vArrIdx].detil_arr||[]) : []);

		if(vDatasourceArr.length <= 0)
			{ setDatadefaultArr([]); return }
		setDatadefaultArr([{init_change:"1"},...vDatasourceArr]) 
	},[uDatamainArr,uIsDetilset])
	React.useEffect(()=>{
		const vArrIdx		= UFunc.getObjectIndeks(uDatamainArr,uFilterObj.pasien_id,"id_pasien");
		const vDatasourceArr= parseInt(uFilterObj.pasien_id||0) <= 0
			? uDatamainArr 
			: (vArrIdx > 0 ? (uDatamainArr[vArrIdx].detil_arr||[]) : []);

		//console.log("(Lappiutang - [vDatasourceArr.length]) vDatasourceArr => "+JSON.stringify(vDatasourceArr) );
		setJmlData(vDatasourceArr.length);
		if(vDatasourceArr.length <= 0)
			{ setDatasumObj({}); return }

		const vSumtransaksi	= vDatasourceArr.reduce(
			(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_transaksi||"0"), 0);
		const vSumsebelum	= vDatasourceArr.reduce(
			(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_sebelum||"0"), 0);
		const vSumbaru	= vDatasourceArr.reduce(
			(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_baru||"0"), 0);
		const vSumbayar		= vDatasourceArr.reduce(
			(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_bayar||"0"), 0);
		const vSumtotal		= vDatasourceArr.reduce(
			(vStores,vItems) => vStores = vStores + parseFloat(vItems.nilai_piutang||"0"), 0);
		setDatasumObj({
			nilai_sebelum: vSumsebelum,
			nilai_transaksi: vSumtransaksi,
			nilai_baru: vSumbaru,
			nilai_bayar: vSumbayar,
			nilai_piutang: vSumtotal,
		});
		setEfekview(!uEfekview)
	},[uDatamainArr,uDatamainArr.length,uIsDetilset])
	React.useEffect(()=>{
		//--LOAD_ALL_VIEW_PER_PAGE--/
		//setDataselectID(0);
		setRowselect(-1);
		//--END LOAD_ALL_VIEW_PER_PAGE--/
	},[uPageActive])//-->Unselect_ROW
	React.useEffect(()=>{
		if(!uHandelView) return;

		uHeaderActionObj.isHeaderView = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) { return; }

		setHeaderDlgform("Pilih Filter Laporan");
		setDlgformshow(true)
		setFormdataObj({...uFilterObj, init_change: 1});
	},[uHandelView])
	React.useEffect(()=>{
		if(!uHandelReset) return;

		uHeaderActionObj.isHeaderreset = false;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});

		if(uHtml500msg) { apiLoadawal(); }
		else { setDataloaded(false); }
	},[uHandelReset])
	React.useEffect(()=>{
		if(!uIsDataloaded)
			{ initResettampilan(); return; }

		//--SET_TITLE--/
		let vTitelmain	= "Periode ";
		vTitelmain += "<b>"+UFunc.HariAngka(uFormdataObj.tgl_awal)+" s.d. "+UFunc.HariAngka(uFormdataObj.tgl_akhir)+"</b>";

		const vCabangIdx= UFunc.getObjectIndeks(uDatacabangArr,uFormdataObj.cabang_id);
		let vTitelsub	= uDatacabangArr.length > 1 ? uDatacabangArr[vCabangIdx].cabang_nama+" &middot " : "";
		vTitelsub		+= "<b>[SEMUA PASIEN]</b>";

		//vTitelsub = "&middot; "+vTitelsub+" &middot;";
		setCaptionObj({title:vTitelmain,title_sub:vTitelsub});
		//--END SET_TITLE--/
	},[uIsDataloaded])
	React.useEffect(()=>{
		//console.log("(Lappiutang - [uIsDlgformshow]) uFilterObj => "+JSON.stringify(uFilterObj));
		if(!uIsDlgformshow) 
			{ setFormdataObj({}); return }

		if(uFormdataObj.cabang_id === undefined)
			uFormdataObj.cabang_id = localStorage.getItem("lastcabangid") 
				|| (uDatacabangArr[0].id || "0");

		const vTanggalnowDT			= new Date();
		const vTanggalLastmonthDT 	= new Date();//UFunc.toDate("2023-02-03");
		if(uFormdataObj.tgl_awal === undefined) {
			vTanggalLastmonthDT.setMonth(vTanggalLastmonthDT.getMonth() - 1);
			uFormdataObj.tgl_awal = vTanggalLastmonthDT.getFullYear()+"-"+
			UFunc.leadZero(vTanggalLastmonthDT.getMonth()+1)+"-26"+
			"";
		}
		if(uFormdataObj.tgl_akhir === undefined)
			uFormdataObj.tgl_akhir = vTanggalnowDT.getFullYear()+"-"+
			UFunc.leadZero(vTanggalnowDT.getMonth()+1)+"-25"+
			"";

		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);

			setElBtnsimpan(document.getElementById("btnDialogSimpan"));
			
			let vElfocus = document.getElementById("inpcabangid");
			if(!vElfocus)
				vElfocus = document.getElementById("inptglawal");

			if(!isMobile)
				vElfocus && vElfocus.focus();
		},350)
	},[uIsDlgformshow])
	React.useEffect(()=>{
		if(!uISDlgviewshow) 
			{ uFilterObj.pasien_id = undefined; return }
	},[uISDlgviewshow])
	React.useEffect(()=>{//--TIDAK_DIPAKAI--/
	},[uFormdataObj,
	uFormdataObj.cabang_id,
	uFormdataObj.bln,
	uFormdataObj.thn,
	])
	React.useEffect(()=>{
		if(uDatamainArr.length <= 0) return;
		if(uPageActive > 1) setPageActive(1);
		else 
			{ setRowselect(-1); }

		if(JSON.stringify(uSortedObj||{}) === "{}") { return; }

		let vSortedArr;
		const vNamasort = (uSortedObj.nama||"").toLowerCase();
		const vISNumeric= UFunc.isNumeric(uDatamainArr[0][vNamasort]);
		if(uSortedObj.jenis === "ASCENDING") {
			vSortedArr	= !vISNumeric
				? uDatamainArr.sort((a, b) => {
					return (a[vNamasort] > b[vNamasort]) ? 1 : ((b[vNamasort] > a[vNamasort]) ? -1 : 0) 
					}) 
				: uDatamainArr.sort((a, b) => a[vNamasort] - b[vNamasort]) ;
		}else if(uSortedObj.jenis === "DESCENDING") {
			vSortedArr	= !vISNumeric
				? uDatamainArr.sort((a, b) => {
					return (b[vNamasort] > a[vNamasort]) ? 1 : ((a[vNamasort] > b[vNamasort]) ? -1 : 0) 
				})
				: uDatamainArr.sort((a, b) => b[vNamasort] - a[vNamasort]);
		} else {
			vSortedArr	= uDatadefaultArr.slice(1);//.splice(0,1);
		}
		setDatamainArr(vSortedArr);
		setEfekview(!uEfekview);
	},[uSortedObj,
	uSortedObj.nama,
	uSortedObj.jenis,
	])
	
	//console.log("(Lappiutang) uActiveroute => "+(uActiveroute));
	if(UFunc.isEmpty(uTokenObj)) { prosesExpired(); return; }
	if(uHtml500msg) return (
		<CCard>
		<CCardHeader className="fw-bolder">Page 500</CCardHeader>
		<CCardBody className="mx-md-5 p-md-5">
		<div className="fst-italic">
		{UFunc.renderHTML(uHtml500msg)}
		</div>
		</CCardBody>
		</CCard>
	)

	return (
		<>
		{(!uIsDataloaded) ? (
		<CCard className="classbgcard">
			<CCardHeader className="fw-bolder">Pilih Filter Laporan</CCardHeader>
			<CCardBody className="mx-lg-5 p-lg-5">
			<div className="mx-md-5 p-md-5 fst-italic classpetunjuk">
			*) Silahkan Pilih Filter, untuk menentukan Parameter Laporan yang akan Ditampilkan. Kemudian Tekan SIMPAN. Untuk Mencetak Silahkan KLIK Ikon Cetak..
			</div>
			</CCardBody>
		</CCard>
		) : (
		<CCard className="classbgcard">
		<CCardHeader className="d-flex justify-content-between px-1 px-md-3">
		<div>
			<div className="d-none d-md-block">{UFunc.renderHTML(uCaptionObj.title)}</div>
			<div className="d-md-none classfontsmaller"><small>{UFunc.renderHTML(uCaptionObj.title)}</small></div>
			<span className="classfontsmaller text-primary">
				<small>
				{UFunc.renderHTML(uCaptionObj.title_sub)}
				</small>
			</span>
		</div>

		<div>
			<span className="d-none d-md-inline-block">
				<CTooltip content="--CETAK..">
				<CLink 
					className="classikon classikoncetak classcursorpointer"
					onClick={()=>hdlKlikCetak()}/>
				</CTooltip>

				<CTooltip content="--Export-2-Excel..">
				<CLink 
					className="classikon classikonexcel classcursorpointer"
					onClick={()=>hdlKlikExcel()}/>
				</CTooltip>
				
				<span className="mx-1">&middot;</span>
			</span>

			<span className="text-muted classfontsmaller">
				<small>{UFunc.formatAngka(uTimeelapse)} ms</small>
			</span>
			<span className="ms-1 d-md-none">&middot;</span>

			<CDropdown className="d-md-none">
				<CDropdownToggle caret={false} size="sm"
					className="p-0" color="transparent">
					<CIcon icon="cilChevronBottom" className="classikonbox mx-1" height={25}/>
				</CDropdownToggle>
				<CDropdownMenu>
					<CDropdownItem onClick={()=>hdlKlikCetak()}>
						<CIcon className="classikoncetak ms-0 me-2 align-middle" height={25}/>
						Cetak Laporan
					</CDropdownItem>
					<CDropdownItem onClick={()=>hdlKlikExcel()}>
						<CIcon className="classikonexcel ms-0 me-2 align-middle" height={25}/>
						Export-2-Excel
					</CDropdownItem>
				</CDropdownMenu>
			</CDropdown>
		</div>
		</CCardHeader>
		
		<CCardBody className="px-0">{contentLaporan()}</CCardBody>

		{(uJmlData > uMaxData) && (
			<CCardFooter>
			<MyPagination 
				activePage={uPageActive}
				pages={uJmlHal}
				onActivePageChange={(i)=>setPageActive(i)}
				align="start"/>
			</CCardFooter>
		)}
		</CCard>
		)}

		<MyDialogform
			options={{centered:true}}
			onSimpan={()=>hdlKlikDlgsimpan()}
			show={uIsDlgformshow} 
			dialogHeader={uHeaderDlgform}
			toggle={hdlToggleDlgform}
			renderContent={contentDlgform()}/>

		<MyDialogview
			options={{size:"lg",centered:true}}
			show={uISDlgviewshow} 
			dialogHeader={uDlgviewdataObj.header||""}
			toggle={()=>setDlgviewshow(false)}
			renderContent={contentDlgview()}/>

		<iframe id="ifmcontentstoprint" className="d-none"/>
		<div className="d-none">
			{(uSystemsettingObj.url_image_nota||"")!=="" && (
				<img src={uSystemsettingObj.url_image_nota} height="100"/>
			)}
		</div>
		</>
	)
}	

export default Lappiutang
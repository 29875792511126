import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CFormSelect,
  CButton,
  CImage,
  CRow
} from '@coreui/react';
import CIcon from '@coreui/icons-react'
import { MyButton } from '../components/index'
import { UFunc } from '../helpers/functions'
import { isMobile } from "react-device-detect";
import useSatusehat from '../helpers/useSatusehat'

const pjson = require('../../package.json');
const Login = (props) => {
	const { 
		setToken,setLoading, showToast 
	} = props; 

	//console.log("Login - props => "+JSON.stringify(props));
	const { getToken } 	= useSatusehat(props);
	const uNavigate  		= useNavigate();
	const uDispatch			= useDispatch();

	const uBahasaob  		= useSelector(state => state.listBahasa);
	const uRouteProfileArr	= useSelector(state => state.profileRoute); 
	const uEfekapp			= useSelector(state => state.effectApp);
	const uConfigappsObj	= useSelector(state => state.uConfigappsObj) || {}; 
	const uCabangattrArr 	= useSelector(state => state.gCabangatribut) || {};
	const uTokenObj			= JSON.parse(localStorage.getItem("token"));

	const uDatacabangArr	= JSON.parse(localStorage.getItem("cabanglist") || "[]");
	const [uInpUsername,setUsername]  		= React.useState(localStorage.getItem("lastusername") || "");
	const [uInpPasswd,setInpPasswd]   		= React.useState('');
	const [uInpCabangID,setInpCabangID]   	= React.useState(localStorage.getItem("lastcabangid") || "0");
	const [uBtnDisabled,setBtnDisabled]		= React.useState(true);
	const [uTokentmpstorage,setTokentmpstorage]= React.useState("");
	const [uISAuthsatusehat,setAuthsatusehat]= React.useState(false);

	const initBrandname	= () =>{
		const vNameArr 	= pjson.name.split("Esys");
		return (
		<>
			<CImage src="../images/icon_logo_small.png" 
				className="align-top d-md-none" style={{marginRight:"-1px"}}
				height={40}/>
			<span className="classfontbrand d-md-none" style={{color:"#144754"}}>{vNameArr[0].replace("OM","M")} E</span>
			<span className="classfontbrand d-none d-md-inline-block" style={{color:"#144754"}}>{vNameArr[0]} E</span>
			<span className="classfontbrandsub fs-2 text-info">system</span>
			<span className="mx-1"/>
			<small>v{pjson.version}</small>
		</>
		)

		/*//--TIDAK_DIPAKAI--/
		const vNameArr 	= pjson.name.split("vo");
		const vLevelapp = (uConfigappsObj.level_app||"").toLowerCase().replace("om","m");
		return (
			<>
			<span className="classfontbrand" style={{color:"#144754"}}>
				{vNameArr[0]}
				<span className="d-md-none">v</span>
				<span className="d-none d-md-inline">v</span>
				<span className="d-none d-md-inline text-info">O</span>
				<CImage src="images/icon_logo_small.png" className="d-md-none" height={40}/>
			</span>
			<span className="classfontbrandsub fs-1 text-info">{vLevelapp}</span>
			<span className="mx-1"/>
			<small>v{pjson.version}</small>
			</>
		)
		//--END TIDAK_DIPAKAI--*/
	}

	const hdlKlikLogin = () => {
		//---VALIDASI--/
		if(parseInt(uInpCabangID) <= 0 || uInpUsername.trim() === "" || uInpPasswd === "") return;
		//---END VALIDASI--/

		apiProseslogin();
	}

	const apiProseslogin = () => {
		setLoading(true);
		setBtnDisabled(true);

		/*//--TEST_FRONTEND--/
		let vTimeout = setTimeout(()=>{
			clearTimeout(vTimeout);
		},2000); return;
		//--END TEST_FRONTEND--*/

		const vDATAS	= JSON.stringify({
			send_cabangid : uInpCabangID,
			send_username : uInpUsername,
			send_passwd : uInpPasswd,
		});
		const vURLs	= pjson.homepage+"api/api_login/pr_login";
		fetch(vURLs,{
			headers: { Accept: "application/json", "Content-Type": "application/json" },
			method: "POST",
			mode: "cors",
			cache: "default",
			body: vDATAS,
		})
		.then((response)=> {
			if (response.status === 200) return response.json();
		})
		.then((output_string) => {
			setLoading(false);
			setBtnDisabled(false);

			//console.log("(Login - apiProseslogin) output_string : "+JSON.stringify(output_string));
			uDispatch({type: "set", listBahasa: JSON.parse(output_string.langs || "{}")});
			uDispatch({type: "set", listNav: JSON.parse(output_string.nav||"[]")});
			
			const vRouteLoadArr = JSON.parse(output_string.routes||"[]");
			const vNewRoutes	= [...uRouteProfileArr,...vRouteLoadArr];
			localStorage.setItem("routeslist", JSON.stringify(vNewRoutes));

			if(output_string.sukses) {
				const vCabangattributObj = JSON.parse(output_string.cabangatribut || "{}");
				uDispatch({type: "set", gCabangatribut: vCabangattributObj});

				localStorage.setItem("lastusername",(uInpUsername||"").trim());
				localStorage.setItem("lastcabangid",uInpCabangID);
				localStorage.setItem("cabanglist", output_string.cabanglist||""); 
						
				uDispatch({type: "set", gSystemsetting:JSON.parse(output_string.systemsetting||"{}")});
				setTokentmpstorage(output_string.token||"{}");

				//console.log("vCabangattributObj.satusehat_secretkey = "+vCabangattributObj.satusehat_secretkey);
				if((vCabangattributObj.satusehat_clientkey || "")===""
					|| (vCabangattributObj.satusehat_secretkey || "")===""
					) {
					setBtnDisabled(true);
					setToken(JSON.parse(output_string.token||"{}"));

					return;
				}

				setAuthsatusehat(true);
			} else if(output_string.info) {
				showToast(output_string.info);
			} else if(output_string.errors) {
				console.log("(Login - apiProseslogin) output_string.errors : "+output_string.errors);
				const vMsg = (pjson.mydefault.environment==="development")
					? output_string.errors : pjson.mydefault.msgFetchError;
				showToast(vMsg,"ERROR");
			} else if(output_string.expired) {
			}
		})
		.catch((error) =>{
			setBtnDisabled(false);
			setLoading(false);
			console.log("(Login - apiProseslogin) catch-error: "+error);
			uNavigate("/error500");
		});
	}

	React.useEffect(() => {
		document.getElementById("idlogowatermark") && (
			document.getElementById("idlogowatermark").style.display = "none"
		)//-*/

		let vElfocus = document.getElementById("inpusername")
		if(uInpUsername.trim() !== "")
			vElfocus = document.getElementById("inppasswd")

		if(!isMobile) vElfocus && vElfocus.focus()

		return ()=>{
			document.getElementById("idlogowatermark") && (
				document.getElementById("idlogowatermark").style.display = "block"
			)
		}
	},[])
	React.useEffect(() => {
		setBtnDisabled(true);
		if(parseInt(uInpCabangID) > 0 
			&& uInpUsername.trim() !== "" 
			&& uInpPasswd !== "") setBtnDisabled(false);
	},[uInpCabangID,uInpUsername,uInpPasswd])
	React.useEffect(() => {
		if(uTokenObj) {
	    	window.location.assign("/dashboard"); return;
		}
	})
	React.useEffect(() => {
		if(uDatacabangArr.length === 1)
			if(parseInt(uInpCabangID) <= 0) {
				setInpCabangID(uDatacabangArr[0].id);
			}
	},[uDatacabangArr, uDatacabangArr.length])
	React.useEffect(() => {
		if(!uISAuthsatusehat) return;

		const vNextfunction = () => {
			setBtnDisabled(false);
			setToken(JSON.parse(uTokentmpstorage));
		}

		setBtnDisabled(true);
		getToken(vNextfunction);
		setTokentmpstorage(""); setAuthsatusehat(false);
	},[uISAuthsatusehat])

	//console.log("(Login) uConfigappsObj => "+JSON.stringify(uConfigappsObj))
	return (
		<>
		<div className="classbglogintop" style={{
			backgroundImage:"url(images/bg_gradient_bottom.png)"
			}}/>
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
			<div className="body flex-grow-1 px-3 d-flex align-items-center">
			<CRow className="justify-content-center w-100 mx-auto">
			<CCol md="3" xs="11" className="d-none d-md-flex flex-row justify-content-center align-items-center">
				<div className="text-center">
				<CImage src="images/logo192.png" height="175" className="m-auto" />
				<div className="mt-2 mx-auto classfontoswald text-uppercase classfontcolormain">
				{(uConfigappsObj.level_app||"").toUpperCase() === "PRO" ? (
					<>&middot; {uConfigappsObj.branch_kode} &middot;</>
				) : (
					<>&middot; {uConfigappsObj.opening_name} &middot;</>
				)}
				</div>
				</div>
			</CCol>
			<CCol md="1" lg="0" className="px-0 border-end"/>
			<CCol md="5" xs="11" className="px-sm-2 px-lg-5 text-center">
				<div className="" style={{paddingBottom:"-9px Important"}}>
				{initBrandname()}
				</div>
				<div className="classfontsmaller">
				{UFunc.renderHTML(uBahasaob.petunjuk_login || "Silahkan Login untuk Masuk dan<br />Menggunakan Aplikasi Ini")}
				</div>

			<CForm className="px-md-4 px-lg-5">
				<CInputGroup className={"my-2"+(uDatacabangArr.length===1?" d-none":"")}>
					<CInputGroupText>
					<CIcon icon="cilBuilding" className="text-primary"/>
					</CInputGroupText>
					<CFormSelect 
						onChange={(e)=>setInpCabangID(e.target.value)} 
						value={uInpCabangID}
						className="bg-white"
						id="inpcabangid">
					<option value="0">--PILIH CABANG</option>
					{uDatacabangArr.map((vItems,vKeys)=>{
						return (
						<option key={vKeys} value={vItems.id}>{(vItems.cabang_nama||"UNDF").toUpperCase()}</option>
						)
					})}
					</CFormSelect> 
				</CInputGroup>

				<CInputGroup className="my-2">
					<CInputGroupText>
					<CIcon icon="cilUser" className="text-primary"/>
					</CInputGroupText>
					<CFormInput type="text" 
						onChange={(e)=>setUsername(e.target.value)} 
						placeholder={(uBahasaob.word_username||"Username")+".."} 
						autoComplete="off" 
						value={uInpUsername}
						id="inpusername"
						className="bg-white"
						required/>
				</CInputGroup>

				<CInputGroup className="my-2">
					<CInputGroupText>
					<CIcon icon="cilLockLocked" className="text-primary"/>
					</CInputGroupText>
					<CFormInput type="password" 
						onChange={(e)=>{setInpPasswd(e.target.value);}} 
						placeholder={(uBahasaob.word_passwd||"Password")+".."} 
						className="bg-white"
						autoComplete="current-password" required
						id="inppasswd"/>
				</CInputGroup>

				<CInputGroup className="my-2 my-md-4">
				<MyButton
					iconname="cilLockUnlocked"
					onClick={()=>hdlKlikLogin()}
					disabled={uBtnDisabled}
					id="btnLogin">L o g i n</MyButton>
				</CInputGroup>
				<div className="text-center">
					<p className="classpetunjuk">
					{uBahasaob.caption_lupapasswd||"Lupa Password"}?{" "}
					<strong className="text-dark">{uBahasaob.caption_hubsupervisor||"Hubungi Supervisor"}</strong>
					</p>
				</div>
			</CForm>
			</CCol>
			</CRow>
			</div>
		</div>	
		</>
	)
}

export default Login

import React from 'react'
import { NavLink,useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
	CImage,
	CForm,CButton,CFormInput,CInputGroup,
	CLink,
	CTooltip,
	CDropdown,CDropdownToggle,CDropdownMenu,CDropdownItem,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { Konfirm } from '../../helpers/onConfirm'
import { UFunc } from '../../helpers/functions'

const pjson 	= require('../../../package.json')
const Headersubkompetensi = (props) => {
	const { setToken,setLoading, showToast } = props 

	//--DOM--/
	const uNavigate 			= useNavigate()
	const uDispatch 			= useDispatch()
	//--END DOM--/

	const uHeaderActionObj  	= useSelector(state => state.gInitHeaderAction)
	const uBahasaObj  			= useSelector(state => state.listBahasa)
	const uActiveroute			= useSelector(state => state.activeRoute)
	const uEfekapp				= useSelector(state => state.effectApp);
	const uKeywordObj			= JSON.parse(localStorage.getItem("listkeyword")) || {};
	const uTokenObj				= JSON.parse(localStorage.getItem("token")||"{}");
	
	const [uInpkeyword,setInpkeyword]= React.useState(uKeywordObj.kwd_kompetensi||"");
	const uISTambahallowed 		= ((uTokenObj.userhak||"")==="FREEROLE" || (uTokenObj.userhak||"")==="ADMIN");

	const hdlKeydown = (_EV) => {
		if(_EV.which === 13) {
			_EV.stopPropagation();
			_EV.preventDefault();

			document.getElementById("btnCari") &&
				document.getElementById("btnCari").click();
		}//-*/
	}
	const hdlKlikCari = () => {
		//---SAVE_KEYWORD--/
		uKeywordObj.kwd_kompetensi = uInpkeyword.trim(); 
    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
		//---END SAVE_KEYWORD--/

		uHeaderActionObj.isHeaderView = true;
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikTambah = () => {
		//console.log("Headersubkompetensi - hdlKlikTambah uHeaderActionObj => "+JSON.stringify(uHeaderActionObj));
		uHeaderActionObj.isHeaderTambah = true;//!(vHeaderTmpObj.isHeaderTambah||false);
		uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
		uDispatch({type: "set", effectApp: !uEfekapp});
	}
	const hdlKlikReset = async() => {
		const vProses = () => {
			//---CLEAR_KEYWORD--/
			uKeywordObj.kwd_kompetensi = undefined; 
	    	localStorage.setItem("listkeyword",JSON.stringify(uKeywordObj));
	    	setInpkeyword("");
			//---END CLEAR_KEYWORD--/
			
			//setHeaderView(true);
			uHeaderActionObj.isHeaderView = true;
			uDispatch({type: "set", gInitHeaderAction: uHeaderActionObj});
			uDispatch({type: "set", effectApp: !uEfekapp});
		}

		if((uInpkeyword||"") !== "") {
			if(await Konfirm(uBahasaObj.confirm_reset||"Apakah Yakin Akan Direset ?")) vProses();
		} else vProses();	
	}

	//console.log("Headersubkompetensi - uActiveroute => "+(uActiveroute));
	return (
		<>
		<div className="d-none d-lg-block">
		<CImage src={pjson.homepage+"api/images/menus_"+(uActiveroute||"").toLowerCase()+".png"} 
			className="me-2"
			height={30}/>
		{uBahasaObj["menus_"+(uActiveroute||"").toLowerCase()]||""}
		</div>

		<CForm>
			<CInputGroup
				style={{minWidth:295,width:"auto"}} 
				size="sm">
			<CFormInput size="sm" 
				value={uInpkeyword} 
				onChange={(e) => setInpkeyword(e.target.value)} 
				onKeyDown={(e) => hdlKeydown(e)} 
				placeholder={"--"+(uBahasaObj.petunjuk_keyword||"Masukkan uKeyword")+".."} 
				id="inpkeyword"/>
			<CButton size="sm" color="light" 
				className="border"
				onClick={()=>hdlKlikCari()} 
				id="btnCari">
				<CIcon icon="cilMagnifyingGlass"/>
			</CButton>
			</CInputGroup>
		</CForm>

		<div className="ms-1 d-none d-md-block">
			{(uISTambahallowed) && (
			<>
			<CTooltip content={"--"+(uBahasaObj.caption_tambahdata||"Tambah Data")}>
			<CLink onClick={()=>hdlKlikTambah()} className="classikon classikontambah classcursorpointer" />
			</CTooltip>
			<span className="mx-1">&middot;</span>
			</>
			)}

			<CTooltip content={"--"+(uBahasaObj.caption_resetdata||"Reset View Tampilan")}>
			<CLink onClick={()=>hdlKlikReset()} className="classikon classikonreset classcursorpointer" />
			</CTooltip>
			
			<span className="mx-1">&middot;</span>
			(<b id="idjmldata" className="text-primary">0</b> {uBahasaObj.word_data||"Data"})
		</div>

		<CDropdown className="d-md-none">
		<CDropdownToggle color="transparent" className="p-0" caret={false}>
			<CIcon icon="cilChevronBottom" className="classikonbox align-middle" height={30}/>
		</CDropdownToggle>

		<CDropdownMenu className="pt-0">
			{(uISTambahallowed) && (
			<CDropdownItem  onClick={()=>hdlKlikTambah()}>
			<CIcon className="classikontambah align-middle mx-0 me-1" height={25}/>Tambah Data
			</CDropdownItem>
			)}

			<CDropdownItem onClick={()=>hdlKlikReset()}>
			<CIcon className="classikonreset align-middle mx-0 me-1" height={25}/>Reset Tampilan
			</CDropdownItem>
		</CDropdownMenu>
		</CDropdown>
		</>
	)
}

export default Headersubkompetensi

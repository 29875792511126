import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
	CContainer,
	CHeader,
	CHeaderBrand,
	CHeaderDivider,
	CHeaderNav,
	CHeaderToggler,
	CImage,
	CCardHeader,
	CCardBody,
	CCard,
	CCardFooter,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { MyButton } from '../components/index'
import { UFunc } from '../helpers/functions'

const pjson 	= require('../../package.json');
const Error500  = (props) => {
	const uNavigate = useNavigate();

	const hdlKlikRefresh=()=>{
		const vLastURL	= localStorage.getItem("lasturl") || "";

		document.getElementById("btnRefresh") 
			&& (document.getElementById("btnRefresh").disabled = true);
    	if(vLastURL==="") {
    		window.location.replace("/");
    		return;
    	}
    	uNavigate(-1);
	}

	return (
		<div className="wrapper d-flex flex-column min-vh-100 bg-transparent">
		<CHeader position="sticky" className="p-0 mb-4">
		<CContainer fluid className="px-2 classheadertop" style={{minHeight:64}}>
			<CHeaderToggler className="px-0"
				onClick={() => hdlKlikRefresh()}>
			<CIcon icon="cilArrowCircleLeft" className="me-2 align-middle" height={30}/>
			</CHeaderToggler>

			<CHeaderBrand className="me-auto">
				<small className="fw-bolder fs-5 d-none d-md-inline">Page ERROR 500</small>
				<small className="fw-bolder d-md-none">Page ERROR 500</small>
			</CHeaderBrand>
		</CContainer>
		</CHeader>
			
		<div className="body flex-grow-1 px-2 px-lg-5">
			<CContainer fluid className="px-0">
			<CCard className="mx-lg-5 text-center classbgcard">
			<CCardHeader>
				<CImage src="images/icon_logo_small.png"
					className=""
					style={{marginRight:"-2px"}}
					height={40}/>
				<span href="/" className="classfontbrand align-middle fs-2">
					{((pjson.name.split("Esys"))[0]).replace("OM","M")}
					<span className="me-1"/>
					<span>E</span>
				</span>
				<span className="classfontbrandsub text-info align-bottom fs-2">system</span>
				<small className="ms-2 mt-3 text-muted">v{pjson.version}</small>
			</CCardHeader>

			<CCardBody className="px-1 px-md-2 px-lg-5 py-1">
			<CImage src={window.location.origin+"/images/img_error500.png"} height="250" />
			<div id="idcontentmsg">{UFunc.renderHTML((pjson.mydefault.msg500page||"UNDF").join(""))}</div>
			</CCardBody>
			<CCardFooter>
				<MyButton 
					iconname="cilReload" 
					id="btnRefresh"
					onClick={()=>hdlKlikRefresh()}>Refresh</MyButton>
			</CCardFooter>
			</CCard>
			</CContainer>
		</div>
		</div>
	)
}

export default Error500
